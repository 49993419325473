import React from 'react';
import {Container, Row, Col, Media} from 'reactstrap';
import styled from "styled-components";
import {data} from './data.js';

const CustomMedia = styled(Media)`
    display: flex;
    flex-direction: row;
    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

class Studies extends React.Component {
    render() {
        return <Container>
            <Row>
                <Col>
                    {data.studies.map(function (study, i) {
                        return <CustomMedia key={i}>
                            <Media top href={study.url}>
                                <Media object src={study.logo} alt={study.institute} style={{maxWidth: "20vmin",height: "auto",borderRadius: "initial"}}/>
                            </Media>
                            <Media body>
                                <Media heading>
                                    <a href={study.url} style={{ textDecoration: "none" }}>{study.institute}</a>
                                </Media>
                                {
                                    [
                                        {
                                            "key": "Title",
                                            "value": study.title
                                        },
                                        {
                                            "key": "Graduation Year",
                                            "value": study.graduationYear
                                        },
                                        {
                                            "key": "Duration",
                                            "value": `${study.durationInYears} years`
                                        }
                                    ].map(function (object, i) {
                                        return <div>
                                            <Row>
                                                <Col className="formLabel">{object.key}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{object.value}</Col>
                                            </Row>
                                        </div>
                                    })
                                }
                            </Media>
                        </CustomMedia>
                    })}
                </Col>
            </Row>
        </Container>
    }
}

export default Studies;