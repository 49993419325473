import React from "react";
import styled from "styled-components";
import Profil from "../Assets/profilCircle.jpg";
import TagManager from 'react-gtm-module';

const Wrapper = styled.div`
    height: calc(100vh - 66px);
    display: flex;
    @media (max-width: 768px) {
        height: calc(100vh - 74px);
    }
`;
const Card = styled.div`
    background-color: white;
    width: 40%;
    height: 50%;
    margin: auto;
    padding: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 579px) {
        width: 90%;
    }
    @media (min-width: 580px) {
        width: 80%;
    }
    @media (min-width: 720px) {
        width: 70%;
    }
    @media (min-width: 1140px) {
        width: 40%;
    }
`;
const ProfilPic = styled.img`
    height: 15vmin;
    width: 15vmin;
    border-radius: 100px;
    margin: 0 auto;
`;
const Info = styled.div`
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-align: center;
`;
const NameWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    @media (max-width: 579px) {
        flex-direction: column;
    }
    &>*{
        @media (max-width: 579px) {
            padding: 0;
            margin: 0 auto;
        }
    }
`;
const Name = styled.h2`
    @media (min-width: 580px) {
        border-right: 2px solid grey;
        padding-right: 10px;
        margin-right: 10px;
    }
`;

//Contact component
const Contact = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'contact'
      }
    });
    return(
        <Wrapper>
            <Card>
                <ProfilPic src={Profil}/>
                <NameWrapper>
                    <Name>Florentin Besset</Name><h4 style={{color: "grey"}}>Web & mobile developer</h4>
                </NameWrapper>
                <div>
                    <Info>
                        Riddes 1908, Switzerland
                    </Info>
                    <Info>
                        florentin.besset@gmail.com - www.florentin-besset.com
                    </Info>
                </div>
            </Card>
        </Wrapper>
    );
};

export default Contact;
